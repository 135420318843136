import { Component, ViewChild } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { ReauthenticateService } from '../services/reauthenticate-service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../global/global-variable';
import { MultifactorAuth } from '../services/2fa-service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-reauthenticate',
  templateUrl: './reauthenticate.component.html',
  styleUrls: ['./reauthenticate.component.scss']
})
export class ReauthenticateComponent {
    @ViewChild('otp_button') otp_button;
    @ViewChild('otp1') otp1;
    @ViewChild('otp2') otp2;
    @ViewChild('otp3') otp3;
    @ViewChild('otp4') otp4;
    @ViewChild('otp5') otp5;
    @ViewChild('otp6') otp6;
    constructor(
        private fb : FormBuilder,
        private reauthenticateService : ReauthenticateService,
        public _activatedRoute : ActivatedRoute,
        public global : Globals,
        private multifactorAuth : MultifactorAuth,
        private toastrService : ToastrService
    ){
        this.snapshot_param = this._activatedRoute.snapshot.params;
    }
    
    form = this.fb.group({
        email: ['', [Validators.required,Validators.pattern('[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
        password: ['',Validators.required],
        phone : [''],
        remember_me : [false]
    });

    otp_form = this.fb.group({
        otp1: ['',[Validators.required,Validators.maxLength(1)]],
        otp2: ['',[Validators.required,Validators.maxLength(1)]],
        otp3: ['',[Validators.required,Validators.maxLength(1)]],
        otp4: ['',[Validators.required,Validators.maxLength(1)]],
        otp5: ['',[Validators.required,Validators.maxLength(1)]],
        otp6: ['',[Validators.required,Validators.maxLength(1)]],
    });

    field_label = {
        email       : "Email address",
        password    : "Password",
        otp1 : "OTP",
        otp2 : "OTP",
        otp3 : "OTP",
        otp4 : "OTP",
        otp5 : "OTP",
        otp6 : "OTP"
    }
    show_password = false;

    snapshot_param = {};

    phone_display = "";

    user_id = "";

    //otp timer
    otp_min = 300;
    timeLeft: number = 300; // 5 minutes in seconds
    timer: any;
    otp = ""

    async ngOnInit(){
        this.global.loading = true;
        const request_param = {
            "record_type" : environment.user_management,
            "pageSize" : 1,
            "filterFieldArr" : [
                "id"
            ],
            "filterOperatorArr" : [
                "=="
            ],
            "filterValueArr" : [
                this.snapshot_param["id"]
            ]
        };
        const api = await lastValueFrom(this.reauthenticateService.filterList(request_param));
        if(api["success"]){
            const query_data = api["data"]["query_data"][0];

            

            
            this.global.loading = false;
        }else{
            this.global.loading = false;
        }//end if
        
    }//end ngOnInit

    async login(){
        this.global.loading = true;
        const val = this.form.value;
        try{
            //-----------------------------------validate login-----------------------------------
            let error_html_arr = new Array();
            if(!this.form.valid){
                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' is invalid format <br/>');
                        
                    }//end if

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['user_not_found'] !== undefined && field.errors?.['user_not_found']){
                        error_html_arr.push('The email you entered did not match our records. Please double-check and try again. <br/>');
                    }//end if

                    if(field.errors?.['password_incorrect'] !== undefined && field.errors?.['password_incorrect']){
                        error_html_arr.push('Incorrect password. Please try again.<br/>');
                    }//end if

             
                }//end for
            }//end if

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "validation_error",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if
            //-----------------------------------validate login-----------------------------------


            //-----------------------------------login-----------------------------------
            const request_param = {
                "username" : val.email,
                "password" : val.password
            }
           
            const result = await lastValueFrom(this.multifactorAuth.generate_otp(request_param));
            console.log('result',result)
            if(result["success"]){
                this.startTimer();
                this.user_id = result["data"]["user_id"];
                this.otp_button.nativeElement.click();
                
            }else{
                console.log(result["data"]);
                let message = result["data"]["error"]["message"];
                if(message === undefined){
                    message = result["data"]["error"]["code"];
                }//end if
                this.toastrService.error(message,"System Message!");
                
            }

            this.global.loading = false;
            //-----------------------------------login-----------------------------------
            
        }catch(e){
            console.log(e.message);
            //this.toastrService.error(e.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }


    }

    handle_focus(step){
        
        //document.getElementById('otp'+step).focus();        
    }

    select_text(id){
        if(id == 'otp1'){
            this.otp1.nativeElement.select();
        }else if(id == 'otp2'){
            this.otp2.nativeElement.select();
        }else if(id == 'otp3'){
            this.otp3.nativeElement.select();
        }else if(id == 'otp4'){
            this.otp4.nativeElement.select();
        }else if(id == 'otp5'){
            this.otp5.nativeElement.select();
        }else if(id == 'otp6'){
            this.otp6.nativeElement.select();
        }//end if
        
       
    }

    pasteOtp(event: ClipboardEvent) {
        const otp_frm = this.otp_form;
        const clipboardData = event.clipboardData;
        const pastedData = clipboardData?.getData('text') || '';
        
        // Split the pasted OTP into individual digits
        const otpDigits = pastedData.split('').slice(0, 6); // Adjust slice according to your needs
        this.otp = otpDigits.join('');
        console.log('otpDigits',otpDigits);
        

        // Set the value of each input field
        

        otpDigits.forEach((digit, index) => {
            let input;

            const i = (index+1);
            if(i == 1){
                input = otp_frm.controls['otp'+i];   
            }else if(i == 2){
                input = otp_frm.controls['otp'+i];   
            }else if(i == 3){
                input = otp_frm.controls['otp'+i];  
            }else if(i == 4){
                input = otp_frm.controls['otp'+i];    
            }else if(i == 5){
                input = otp_frm.controls['otp'+i]; 
            }else if(i == 6){
                input = otp_frm.controls['otp'+i];
            }//end if

            if (input) {
                input.setValue(digit);
               
            }//end if
        });
    }


    onInput(event: Event, index: number) {
        /*const input = event.target as HTMLInputElement;
        const value = input.value;

        // Update the OTP string
        let otp_arr = this.otp.split(''); 
        otp_arr[index] = value;
        this.otp = otp_arr.join('');

        // Move to the next input if a digit is entered
        if (value && index < 5) {
            const nextInput = document.querySelector(`input:nth-of-type(${index + 1})`) as HTMLInputElement;
            //nextInput.focus();
        }*/
    }

   async submit_otp(){
        this.global.loading = true;
        const val = this.otp_form.value;
        const login_frm = this.form.value;
        const otp = val.otp1.toString()+val.otp2.toString()+val.otp3.toString()+val.otp4.toString()+val.otp5.toString()+val.otp6.toString();

        try{
            //-----------------------------------validate login-----------------------------------
            let error_html_arr = new Array();
            if(!this.otp_form.valid){
                for(var k in this.otp_form.controls){
                    var field = this.otp_form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' is invalid format <br/>');
                        
                    }//end if

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['user_not_found'] !== undefined && field.errors?.['user_not_found']){
                        error_html_arr.push('The email you entered did not match our records. Please double-check and try again. <br/>');
                    }//end if

                    if(field.errors?.['password_incorrect'] !== undefined && field.errors?.['password_incorrect']){
                        error_html_arr.push('Incorrect password. Please try again.<br/>');
                    }//end if

             
                }//end for
            }//end if

            if(error_html_arr.length > 0){
                var error_html_arr_str = '';
                for(var key in error_html_arr){
                    error_html_arr_str += error_html_arr[key];
                }//end if

                const errObj = {
                    code : "validation_error",
                    message : error_html_arr_str
                }
                throw errObj;
                //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                //return;
            }//end if

            const request_param = {
                "username" : login_frm.email,
                "password" : login_frm.password,
                "otp" : otp
            };

            console.log('request_param',request_param);
            const result = await lastValueFrom(this.multifactorAuth.verify_otp(request_param));
            if(result["success"]){
                this.toastrService.success("Multifactor is now enabled.","System Message")
                setTimeout(() => {
                    window.location.replace('main/account');    
                }, 3000);
            }else{
                console.log(result["data"]);
                let message = result["data"]["error"]["message"];
                if(message === undefined){
                    message = result["data"]["error"]["code"];
                }//end if
                this.toastrService.error(message,"System Message!");
                
            }
            //-----------------------------------validate login-----------------------------------

            this.global.loading = false;
        }catch(e){
            console.log(e.message);
            //this.toastrService.error(e.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }
        
        /*this.global.loading = true;
        const val = this.otp_form.value;
        
        const otp = val.otp1+val.otp2+val.otp3+val.otp4+val.otp5+val.otp6;
        //-----------------------------------login-----------------------------------
        const request_param = {
            "user_id" : this.user_id,
            "otp" : otp
        };
        const result = await lastValueFrom(this.multifactorAuth.verify_otp(request_param));
        
        if(result["success"]){
            this.timeLeft = this.otp_min;
            this.startTimer();
            //this.otp_button.nativeElement.click();
            
        }else{
            console.log(result["data"]);
            let message = result["data"]["error"]["message"];
            if(message === undefined){
                message = result["data"]["error"]["code"];
            }//end if
            this.toastrService.error(message,"System Message!");
            
        }

        this.global.loading = false;
        */
    }

    async resend_otp(){
        this.global.loading = true;
        const val = this.form.value;
        //-----------------------------------login-----------------------------------
        const request_param = {
            "username" : val.email,
            "password" : val.password
        }
       
        const result = await lastValueFrom(this.multifactorAuth.generate_otp(request_param));
        
        if(result["success"]){
            this.timeLeft = this.otp_min;
            this.startTimer();
            //this.otp_button.nativeElement.click();
            
        }else{
            console.log(result["data"]);
            let message = result["data"]["error"]["message"];
            if(message === undefined){
                message = result["data"]["error"]["code"];
            }//end if
            this.toastrService.error(message,"System Message!");
            
        }

        this.global.loading = false;
        //-----------------------------------login-----------------------------------
    }


    startTimer() {
        this.timer = setInterval(() => {
            if (this.timeLeft > 0) {
            this.timeLeft--;
            } else {
            clearInterval(this.timer);
            }
        }, 1000); // Update every second
    }
    
    ngOnDestroy() {
        clearInterval(this.timer); // Clear the timer on component destruction
    }
    
    get formattedTime() {
        const minutes = Math.floor(this.timeLeft / 60);
        const seconds = this.timeLeft % 60;
        return `${this.padZero(minutes)}:${this.padZero(seconds)}`;
    }
    
    padZero(num: number) {
        return num < 10 ? '0' + num : num;
    }

    

    s_password(name){
        
        if(name == 'password'){
            if(this.show_password){
                this.show_password = false;
            }else{
                this.show_password = true;
            }//end if
            
        }//end if
    }
}
