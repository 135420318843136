
<div class="container-fluid">
  
    
    <div class="row align-items-center justify-content-start ms-3 me-3">
        <div class="col-auto">
            Trial Subscription
        </div>

        <!--
        <div class="col col-auto">
            <div class="d-grid gap-2">
                <button type="button" *ngIf="show_add_btn" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal_view">Add New User <img src="../../assets/images/add.svg" /></button>
            </div>
            
        </div>
        <div class="col">

        </div>
        <div class="col-auto">
            <div class="input-group">
                <span class="input-group-text bg-white">
                    <img style="width:0.15in;height:0.15in;" src="{{'../../assets/images/search-icon.svg'}}">
                </span>
                <input style="border-left:none;" (keyup.enter)="search()" formControlName="filter_search" (blur)="search()" placeholder="Search name"  type="text" class="form-control">
            </div>
            
        </div>
        -->
    </div>
  
   
    

    <div class="row justify-content-center ms-3 me-3">
        <div class="col">
            <p class="text-muted"></p>
            <div class="shadow bg-white rounded">
                

                <div class="card border-0 shadow">
                    <div class="card-body" style="min-height:501px;">
    
                        <form [formGroup]="form">
                            <div class="row justify-content-start align-items-center">
                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-auto">
                                    <small class="form-label text-muted">User</small>
                                    <select class="form-select" formControlName="user">
                                                
                                        <option value="">All user</option>
                                        <option *ngFor="let data of select_data_list" value="{{data.id}}">{{data.first_name}} {{data.last_name}}</option>
                                    </select>
                                    
                                </div>

                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-auto">
                                    <small class="form-label text-muted">Expiration</small>
                                    <div class="input-group">
                                        <ngx-datepicker #expiration formControlName="expiration" [options]="date_option"></ngx-datepicker>
                                    </div>
                                    
                                    
                                </div>

                                <div class="col-xxl-3 col-xl-3 col-lg-3 col-auto">
                                    <div class="d-grid gap-2">
                                        <button class="btn btn-primary fw-bold" (click)="save_data()">Save</button>
                                    </div>
                                </div>
                                
                            </div>

                           
                            
                        </form>
                    </div>
                </div>
                

                

            </div>
            
        </div>
    </div>
</div>


