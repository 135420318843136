import { Component } from '@angular/core';
import { FormBuilder,Validators } from '@angular/forms';
import { Globals } from '../global/global-variable';
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CheckOutService } from '../services/checkout-service';
import { lastValueFrom } from 'rxjs';
import { CommonService } from '../library/pcl_lib';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent {
    form = this.fb.group({
        first_name : ['',[Validators.required]],
        middle_name : [''],
        last_name : ['',[Validators.required]],
        phone : [''],
        email : ['',[Validators.required,Validators.pattern('[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')]],
        card_number: ['', [Validators.required,Validators.pattern('^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$')]],
        expiration: ['',Validators.required],
        cvv: ['',[Validators.required,Validators.minLength(3),Validators.maxLength(3)]],
        plan: ['',Validators.required],
        promo_code : ['']
      
    });

    field_label = {
        first_name : "First name",
        middle_name : "Middle name",
        last_name : "Last name",
        email : "Email",
        card_number : "Card number",
        expiration : "Expiration",
        cvv : "CVV",
        plan : "Plan",
    }
    snapshot_param = {};
    plan_list = ['basic','complete'];
    subscription_list = {};
    constructor(
        public global           : Globals,
        private fb              : FormBuilder,
        private router          : Router,
        private toastr          : ToastrService,
        private _activatedRouter : ActivatedRoute,
        private checkoutService : CheckOutService,
        public commonService : CommonService
        ){
            this.snapshot_param = this._activatedRouter.snapshot.params;
    }

    async ngOnInit(){
        this.global.loading = true;
        this.form.controls['plan'].setValue(this.snapshot_param["plan"]);

        

        const data_api = await lastValueFrom(this.checkoutService.getSubscriptionList());
        if(data_api["success"]){
            this.subscription_list = data_api["data"];
            var plan = this.subscription_list[this.snapshot_param["plan"]]
            
            var plan_name = this.snapshot_param["plan"] + " subscription";
            plan_name =  this.commonService.ucwords(plan_name);

            var plan_description = "";
            if(plan["description"] !== undefined){
                plan_description = plan["description"];
            }//end if

            var img = "https://picsum.photos/280/320?random=1";

            if(plan["img_url"] !== undefined){
                img = plan["img_url"];
            }//end if

            var request_param = {
                "currency": "php",
                "payment_method_types": [
                    "card"
                ],
                "line_items": [
                    {
                        "name": plan_name,
                        //"amount": (plan["cost"] * 100),
                        "amount": 100,
                        "quantity": 1,
                        "currency": "php",
                        "description" : plan_description,
                        "image": img
                    }
                ],
                "mode": "subscription",
                "success_url": window.location.origin+"/verification/success",
                "cancel_url": window.location.origin+"/verification/cancel",
                "metadata" : {
                    "subscription" : this.snapshot_param["plan"],
                    "totalAmount" : plan["cost"]
                }
            };

            const data_api2 = await lastValueFrom(this.checkoutService.process_subscription_zipph(request_param));
            console.log(data_api2);
            if(data_api2["success"]){
                window.location.replace(data_api2["data"]["payment_url"]);
            }else{
                
                this.toastr.error(data_api2["data"]["error"]["message"],"System Message");
            }//end if
            this.global.loading = false;
        }else{
            this.toastr.error("Error fetching subscription","System Message");
        }//end if
        this.global.loading = false;

    }


        
    

    save_data(){
        this.global.loading = true;
        const val = this.form.value;
        try{
            let error_html_arr = new Array();
            if(!this.form.valid){
                for(var k in this.form.controls){
                    var field = this.form.controls[k];
                    field.markAsTouched();

                    if(field.errors?.['pattern'] !== undefined && field.errors?.['pattern']){
                        error_html_arr.push(this.field_label[k]+' is invalid format <br/>');
                        
                    }//end if

                    if(field.errors?.['required'] !== undefined && field.errors?.['required']){
                        error_html_arr.push(this.field_label[k]+' is required <br/>');
                        
                    }//end if

                    if(field.errors?.['user_not_found'] !== undefined && field.errors?.['user_not_found']){
                        error_html_arr.push('The email you entered did not match our records. Please double-check and try again. <br/>');
                    }//end if

                    if(field.errors?.['password_incorrect'] !== undefined && field.errors?.['password_incorrect']){
                        error_html_arr.push('Incorrect password. Please try again.<br/>');
                    }//end if

                    if(field.errors?.['minlength'] !== undefined && field.errors?.['minlength']){
                        error_html_arr.push(this.field_label[k]+' must have 3 minimum characters <br/>');
                        
                    }//end if

                    if(field.errors?.['maxlength'] !== undefined && field.errors?.['maxlength']){
                        error_html_arr.push(this.field_label[k]+' must have 3 minimum characters <br/>');
                        
                    }//end if
                }//end for

                if(error_html_arr.length > 0){
                    var error_html_arr_str = '';
                    for(var key in error_html_arr){
                        error_html_arr_str += error_html_arr[key];
                    }//end if
    
                    const errObj = {
                        code : "Require Field",
                        message : error_html_arr_str
                    }
                    throw errObj;
                    //this.toastr.error(error_html_arr_str,'System Message!',{enableHtml : true});
                    //return;
                }//end if

                

            }//end if

            var contact_obj = {};
            if(val.phone.trim() !== ""){
                contact_obj["phone"] = val.phone.trim();
            };
            contact_obj["email"] = val.email.trim();

            var expiration = val.expiration.split("/");

            var request_param = {
                "subscription" : val.plan,
                "redirectUrl" : {
                    "success" : window.location.origin+"/maya_verification/success",
                    "failure" : window.location.origin+"/maya_verification/failure",
                    "cancel" : window.location.origin+"/maya_verification/cancel",
                },
                "maya" : {
                    "customer_info" : {
                        "contact": contact_obj,
                        "firstName" : val.first_name.trim(),
                        "lastName" : val.last_name.trim(),
                    },
                    "card" : {
                        "number" : val.card_number.trim(),
                        "expMonth" : expiration[0],
                        "expYear" : expiration[1],
                        "cvc" : val.cvv.trim(),

                    },
                    "totalAmount" : {
                        "amount" : this.subscription_list[val.plan]["cost"],
                        "currency" : "PHP"
                    }
                }
                
            };

            if(val.middle_name.trim() !== ""){
                request_param["maya"]["customer_info"]["middleName"] = val.middle_name.trim();
            }//end if

            console.log("request_param",request_param);

            this.checkoutService.process_payment(request_param).subscribe(res => {
                if(res["success"]){
                    const payment_info = res["data"][3];
                    window.location.replace(payment_info["verificationUrl"]);
                }else{
                    console.log(res["data"]);
                    this.toastr.error("Error processing payment","System Message")
                }
                
                this.global.loading = false;
            })

           
        }catch(e : any){
            //this.toastr.error(e.message,"System Message",{enableHtml : true});
            this.global.loading = false;
        }//end try
    }
}
