
<div class="container-fluid">
   
    <form [formGroup]="filter_form" >
        <div class="row align-items-center justify-content-start ms-3 me-3">
            <div class="col col-auto">
                All users
            </div>
            
            <div class="col">
    
            </div>
            <div class="col-lg-4 col-auto">
                <div class="input-group">
                    <span class="input-group-text bg-white">
                        <img style="width:0.15in;height:0.15in;" src="{{'../../assets/images/search-icon.svg'}}">
                    </span>
                    <input style="border-left:none;" (keyup.enter)="search()" formControlName="filter_search" (blur)="search()" placeholder="Search name"  type="text" class="form-control">
                </div>
                
            </div>

            <div class="col-auto">
                <div class="d-grid gap-2">
                    <button type="button" *ngIf="show_add_btn" class="btn btn-primary font-700" data-bs-toggle="modal" data-bs-target="#modal_view">Add New User <img src="../../assets/images/add.svg" /></button>
                </div>
                
            </div>
        </div>
    </form>
   

    <div class="row ms-3 me-3">
        <div class="col-12">
            <hr/>

            <div class="custom-module-header mb-3" *ngIf="this.global.login_session['team'] !== undefined">
                <div class="row align-items-center" style="min-height: 90px;">
                    <div class="col-auto ms-5">
                        <span class="custom-module-header-text">{{this.global.login_session["team"]["name"]}}</span>
                    </div>
                    <div class="col-auto">
                        <a *ngIf="this.show_edit_team_btn" href="#" (click)="edit_team()" data-bs-toggle="modal" data-bs-target="#team_view">
                            <img style="width: 21px; height: 21px;" src="../../assets/images/edit-logo.png" />
                        </a>
                        
                    </div>
                </div>
            </div>
                
                
            

            <ul class="nav nav-pills-custom" id="pills-tab" role="tablist">
                <li class="nav-item me-2" role="presentation">
                    <button class="nav-link active fw-bold" id="pills-all-tab" (click)="change_tab($event,'pills-all-tab')" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">All Members</button>
                </li>
                <li class="nav-item me-2" role="presentation">
                    <button class="nav-link fw-bold" id="pills-member-tab" (click)="change_tab($event,'pills-member-tab')" data-bs-toggle="pill" data-bs-target="#pills-member" type="button" role="tab" aria-controls="pills-member" aria-selected="false">Members</button>
                </li>
                <li class="nav-item me-2" role="presentation">
                    <button class="nav-link fw-bold" id="pills-admin-tab" (click)="change_tab($event,'pills-admin-tab')" data-bs-toggle="pill" data-bs-target="#pills-admin" type="button" role="tab" aria-controls="pills-admin" aria-selected="false">Admin</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link fw-bold" id="pills-owner-tab" (click)="change_tab($event,'pills-owner-tab')" data-bs-toggle="pill" data-bs-target="#pills-owner" type="button" role="tab" aria-controls="pills-owner" aria-selected="false">Owner</button>
                </li>
            </ul>

            
        
            <div class="tab-content" id="pills-tabContent" style="max-height:500px;">
                <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab" tabindex="0">
                    <div *ngIf="total_search_result > 0" class="table-responsive shadow bg-white rounded">
                        <p class="text-muted"></p>
                
                        <table class="table align-middle" style="min-width:2in;">
                            <thead>
                                <tr>
                                    <th class="text-muted text-center px-3">No.</th>
                                    <th class="text-muted px-3">Name</th>
                                    <th class="text-muted px-3">Email</th>
                                    <th class="text-muted px-3">Role</th>
                                    <th class="text-muted px-3">Status</th>
                                    <th colspan="3" class="text-muted text-center px-3">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of table_list; let x = index">
                                    <td class="text-center px-3">{{row_counter + x}}</td>
                                    <td class="text-left px-3 fw-bold">{{data.first_name}} {{data.last_name}}</td>
                                    <td class="text-left px-3">{{data.username}}</td>
                                    <td class="text-left px-3">{{commonService.ucwords((data.user_type == 'user' ? 'member' : data.user_type))}}</td>
                                    <td [ngClass]="{'fw-bold text-success' : data.status !== undefined && data.status.toLocaleLowerCase() == 'accepted', 'text-danger fw-bold' : data.status !== undefined && data.status.toLocaleLowerCase() == 'expired'}" class="text-left px-3">{{data.status}}</td>
                                    <td style="width:0.6in;" class="text-center">
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'owner'" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'admin' && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'user' && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner' && data['user_type'] !== 'admin')" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'super_admin' && show_edit_btn" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                    </td>
                                    <td style="width:0.6in;" class="text-center">
                                        
                                        
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'super_admin' && show_delete_btn && data['id'] !== this.global.login_session['id']" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />

                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'owner' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'admin' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />
                                        <!--<img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'user' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner' && data['user_type'] !== 'admin')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />-->

                                    </td>

                                    <td style="width:0.6in;" class="text-center">
                                        <a href="javascript: void(0);" *ngIf="(this.show_send_invite_btn) && (data['team_id'] === undefined || data['team_id'] === undefined) && (data['status'] === undefined || data['status'].toLowerCase() === 'expired') && this.global.login_session['team_id'] !== undefined" (click)="set_for_invite(data)">
                                            <img style="width:18.98px;height: 15.11px;" src="../../assets/images/send-email-logo.png" />
                                        </a>

                                        
                                        <img #send_invite_modal class="d-none" style="width:18.98px;height: 15.11px;" *ngIf="(data['user_type'] !== 'super_admin' || data['user_type'] !== 'owner') && data['team_id'] === undefined" data-bs-toggle="modal" data-bs-target="#send_invite_modal" src="../../assets/images/send-email-logo.png" />
                                        <img #success_delete_btn class="d-none" style="width:18.98px;height: 15.11px;"  data-bs-toggle="modal" data-bs-target="#success_delete_modal" src="../../assets/images/send-email-logo.png" />
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table>
            
                            
                   
        
                        <div class="row container-fluid justify-content-end align-items-center mt-4">
                            <div class="col-auto">
                                <form [formGroup]="filter_form">
        
                                    <div class="mb-3 row align-items-center">
                                        <label for="inputPassword" class="col-auto col-form-label font-14">
                                            Rows per page
                                        </label>
                                        <div class="col">
                                            <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                            </select>
                                        </div>
                                    </div>
        
                                </form>
                            </div>
        
                            <div class="col-auto">
                                <div class="mb-3 row align-items-center">
                                    <div class="col-auto">
                                        {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div class="col-auto">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination border-opacity-10">
                                        <li class="page-item " (click)="prev()" >
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                            </a>
                                        </li>
                                        
                                        <li class="page-item" (click)="next()">
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div><!--./table-responsive-->

                    <div class="card border-0 shadow" *ngIf="total_search_result == 0">
                        <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
        
                            <div class="col-auto">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-auto">
                                        <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                    </div>
                                    <div class="col-12">
                                        <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--./tab-pane-->
                <div class="tab-pane fade" id="pills-member" role="tabpanel" aria-labelledby="pills-member-tab" tabindex="0">
                    <div *ngIf="total_search_result > 0" class="table-responsive shadow bg-white rounded">
                        <p class="text-muted"></p>
                        <table class="table align-middle" style="min-width:2in;">
                            <thead>
                                <tr>
                                    <th class="text-muted text-center px-3">No.</th>
                                    <th class="text-muted px-3">Name</th>
                                    <th class="text-muted px-3">Email</th>
                                    <th class="text-muted px-3">Role</th>
                                    <th class="text-muted px-3">Status</th>
                                    <th colspan="3" class="text-muted text-center px-3">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of table_list; let x = index" >
                                    <td class="text-center px-3">{{row_counter + x}}</td>
                                    <td class="text-left px-3 fw-bold">{{data.first_name}} {{data.last_name}}</td>
                                    <td class="text-left px-3">{{data.username}}</td>
                                    <td class="text-left px-3">{{commonService.ucwords((data.user_type == 'user' ? 'member' : data.user_type))}}</td>
                                    <td [ngClass]="{'fw-bold text-success' : data.status !== undefined && data.status.toLocaleLowerCase() == 'accepted', 'text-danger fw-bold' : data.status !== undefined && data.status.toLocaleLowerCase() == 'expired'}" class="text-left px-3">{{data.status}}</td>
                                    <td style="width:0.6in;" class="text-center">

                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'owner'" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'admin' && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'user' && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner' && data['user_type'] !== 'admin')" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'super_admin' && show_edit_btn" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                    
                                        
                                        
                                    </td>
                                    <td style="width:0.6in;" class="text-center">
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'super_admin' && show_delete_btn && data['id'] !== this.global.login_session['id']" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'owner' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'admin' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />
                                        <!--<img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'user' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner' && data['user_type'] !== 'admin')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />-->
                                    </td>

                                    <td style="width:0.6in;" class="text-center">

                                        <a href="javascript: void(0);" *ngIf="(this.show_send_invite_btn) && (data['team_id'] === undefined || data['team_id'] === undefined) && (data['status'] === undefined || data['status'].toLowerCase() === 'expired') && this.global.login_session['team_id'] !== undefined" (click)="set_for_invite(data)">
                                            <img style="width:18.98px;height: 15.11px;" src="../../assets/images/send-email-logo.png" />
                                        </a>

                                        
                                        <img #send_invite_modal class="d-none" style="width:18.98px;height: 15.11px;" *ngIf="(data['user_type'] !== 'super_admin' || data['user_type'] !== 'owner') && data['team_id'] === undefined" data-bs-toggle="modal" data-bs-target="#send_invite_modal" src="../../assets/images/send-email-logo.png" />
                                        <img #success_delete_btn class="d-none" style="width:18.98px;height: 15.11px;"  data-bs-toggle="modal" data-bs-target="#success_delete_modal" src="../../assets/images/send-email-logo.png" />

                                        
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table><!--./table-->

                        <div class="row container-fluid justify-content-end align-items-center mt-4">
                            <div class="col-auto">
                                <form [formGroup]="filter_form">
        
                                    <div class="mb-3 row align-items-center">
                                        <label for="inputPassword" class="col-auto col-form-label font-14">
                                            Rows per page
                                        </label>
                                        <div class="col">
                                            <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                            </select>
                                        </div>
                                    </div>
        
                                </form>
                            </div>
        
                            <div class="col-auto">
                                <div class="mb-3 row align-items-center">
                                    <div class="col-auto">
                                        {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div class="col-auto">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination border-opacity-10">
                                        <li class="page-item " (click)="prev()" >
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                            </a>
                                        </li>
                                        
                                        <li class="page-item" (click)="next()">
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div><!--./table-responsive-->
                    
                    <div class="card border-0 shadow" *ngIf="total_search_result == 0">
                        <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
        
                            <div class="col-auto">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-auto">
                                        <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                    </div>
                                    <div class="col-12">
                                        <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--./tab-pane-->


                <div class="tab-pane fade" id="pills-admin" role="tabpanel" aria-labelledby="pills-admin-tab" tabindex="0">
                    <div *ngIf="total_search_result > 0" class="table-responsive shadow bg-white rounded">
                        <p class="text-muted"></p>
                        <table class="table align-middle" style="min-width:2in;">
                            <thead>
                                <tr>
                                    <th class="text-muted text-center px-3">No.</th>
                                    <th class="text-muted px-3">Name</th>
                                    <th class="text-muted px-3">Email</th>
                                    <th class="text-muted px-3">Role</th>
                                    <th class="text-muted px-3">Status</th>
                                    <th colspan="3" class="text-muted text-center px-3">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of table_list; let x = index" >
                                    <td class="text-center px-3">{{row_counter + x}}</td>
                                    <td class="text-left px-3 fw-bold">{{data.first_name}} {{data.last_name}}</td>
                                    <td class="text-left px-3">{{data.username}}</td>
                                    <td class="text-left px-3">{{commonService.ucwords((data.user_type == 'user' ? 'member' : data.user_type))}}</td>
                                    <td [ngClass]="{'fw-bold text-success' : data.status !== undefined && data.status.toLocaleLowerCase() == 'accepted', 'text-danger fw-bold' : data.status !== undefined && data.status.toLocaleLowerCase() == 'expired'}" class="text-left px-3">{{data.status}}</td>
                                    <td style="width:0.6in;" class="text-center">
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'owner'" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'admin' && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'user' && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner' && data['user_type'] !== 'admin')" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'super_admin' && show_edit_btn" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />

                                    </td>
                                    <td style="width:0.6in;" class="text-center">
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'super_admin' && show_delete_btn && data['id'] !== this.global.login_session['id']" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'owner' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'admin' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />
                                        <!--<img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'user' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner' && data['user_type'] !== 'admin')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />-->
                                    </td>

                                    <td style="width:0.6in;" class="text-center">
                                        <a href="javascript: void(0);" *ngIf="(this.show_send_invite_btn) && (data['team_id'] === undefined || data['team_id'] === undefined) && (data['status'] === undefined || data['status'].toLowerCase() === 'expired') && this.global.login_session['team_id'] !== undefined" (click)="set_for_invite(data)">
                                            <img style="width:18.98px;height: 15.11px;" src="../../assets/images/send-email-logo.png" />
                                        </a>

                                        
                                        <img #send_invite_modal class="d-none" style="width:18.98px;height: 15.11px;" *ngIf="(data['user_type'] !== 'super_admin' || data['user_type'] !== 'owner') && data['team_id'] === undefined" data-bs-toggle="modal" data-bs-target="#send_invite_modal" src="../../assets/images/send-email-logo.png" />
                                        <img #success_delete_btn class="d-none" style="width:18.98px;height: 15.11px;"  data-bs-toggle="modal" data-bs-target="#success_delete_modal" src="../../assets/images/send-email-logo.png" />
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table><!--./table-->

                        <div class="row container-fluid justify-content-end align-items-center mt-4">
                            <div class="col-auto">
                                <form [formGroup]="filter_form">
        
                                    <div class="mb-3 row align-items-center">
                                        <label for="inputPassword" class="col-auto col-form-label font-14">
                                            Rows per page
                                        </label>
                                        <div class="col">
                                            <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                            </select>
                                        </div>
                                    </div>
        
                                </form>
                            </div>
        
                            <div class="col-auto">
                                <div class="mb-3 row align-items-center">
                                    <div class="col-auto">
                                        {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div class="col-auto">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination border-opacity-10">
                                        <li class="page-item " (click)="prev()" >
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                            </a>
                                        </li>
                                        
                                        <li class="page-item" (click)="next()">
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div><!--./table-responsive-->
                    
                    <div class="card border-0 shadow" *ngIf="total_search_result == 0">
                        <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
        
                            <div class="col-auto">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-auto">
                                        <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                    </div>
                                    <div class="col-12">
                                        <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--./tab-pane-->


                <div class="tab-pane fade" id="pills-owner" role="tabpanel" aria-labelledby="pills-owner-tab" tabindex="0">
                    <div *ngIf="total_search_result > 0" class="table-responsive shadow bg-white rounded">
                        <p class="text-muted"></p>
                        <table class="table align-middle" style="min-width:2in;">
                            <thead>
                                <tr>
                                    <th class="text-muted text-center px-3">No.</th>
                                    <th class="text-muted px-3">Name</th>
                                    <th class="text-muted px-3">Email</th>
                                    <th class="text-muted px-3">Role</th>
                                    <th class="text-muted px-3">Status</th>
                                    <th colspan="3" class="text-muted text-center px-3">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of table_list; let x = index" >
                                    <td class="text-center px-3">{{row_counter + x}}</td>
                                    <td class="text-left px-3 fw-bold">{{data.first_name}} {{data.last_name}}</td>
                                    <td class="text-left px-3">{{data.username}}</td>
                                    <td class="text-left px-3">{{commonService.ucwords((data.user_type == 'user' ? 'member' : data.user_type))}}</td>
                                    <td [ngClass]="{'fw-bold text-success' : data.status !== undefined && data.status.toLocaleLowerCase() == 'accepted', 'text-danger fw-bold' : data.status !== undefined && data.status.toLocaleLowerCase() == 'expired'}" class="text-left px-3">{{data.status}}</td>
                                    <td style="width:0.6in;" class="text-center">
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'owner'" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'admin' && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="show_edit_btn && global.login_session['user_type'] == 'user' && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner' && data['user_type'] !== 'admin')" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'super_admin' && show_edit_btn" data-bs-toggle="modal" data-bs-target="#modal_view" (click)="edit_data(data)" src="../../assets/images/edit.svg" />

                                    </td>
                                    <td style="width:0.6in;" class="text-center">
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'super_admin' && show_delete_btn && data['id'] !== this.global.login_session['id']" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'owner' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />
                                        <img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'admin' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />
                                        <!--<img style="width:18.98px;height: 15.11px;" *ngIf="global.login_session['user_type'] == 'user' && show_delete_btn && data['id'] !== this.global.login_session['id'] && (data['user_type'] !== 'super_admin' && data['user_type'] !== 'owner' && data['user_type'] !== 'admin')" data-bs-toggle="modal" data-bs-target="#confirmation_modal" (click)="set_for_delete(data)" src="../../assets/images/delete.svg" />-->
                                    </td>

                                    <td style="width:0.6in;" class="text-center">
                                        <a href="javascript: void(0);" *ngIf="(this.show_send_invite_btn) && (data['team_id'] === undefined || data['team_id'] === undefined) && (data['status'] === undefined || data['status'].toLowerCase() === 'expired') && this.global.login_session['team_id'] !== undefined" (click)="set_for_invite(data)">
                                            <img style="width:18.98px;height: 15.11px;" src="../../assets/images/send-email-logo.png" />
                                        </a>

                                        
                                        <img #send_invite_modal class="d-none" style="width:18.98px;height: 15.11px;" *ngIf="(data['user_type'] !== 'super_admin' || data['user_type'] !== 'owner') && data['team_id'] === undefined" data-bs-toggle="modal" data-bs-target="#send_invite_modal" src="../../assets/images/send-email-logo.png" />
                                        <img #success_delete_btn class="d-none" style="width:18.98px;height: 15.11px;"  data-bs-toggle="modal" data-bs-target="#success_delete_modal" src="../../assets/images/send-email-logo.png" />
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table><!--./table-->

                        <div class="row container-fluid justify-content-end align-items-center mt-4">
                            <div class="col-auto">
                                <form [formGroup]="filter_form">
        
                                    <div class="mb-3 row align-items-center">
                                        <label for="inputPassword" class="col-auto col-form-label font-14">
                                            Rows per page
                                        </label>
                                        <div class="col">
                                            <select style="border-style:none !important;" (change)="search()" formControlName="page_size_placeholder" class="form-select form-control-plaintext" aria-label=".form-select-sm example" id="ss">
                                                <option *ngFor="let x of pageSize" [value]="x" >{{x}}</option>
                                            </select>
                                        </div>
                                    </div>
        
                                </form>
                            </div>
        
                            <div class="col-auto">
                                <div class="mb-3 row align-items-center">
                                    <div class="col-auto">
                                        {{row_counter}} - {{pagination_counter}} of {{total_search_result}}
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div class="col-auto">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination border-opacity-10">
                                        <li class="page-item " (click)="prev()" >
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-left.svg"></span>
                                            </a>
                                        </li>
                                        
                                        <li class="page-item" (click)="next()">
                                            <a  class="page-link" href="javascript: void(0);" style="border-style:none !important;">
                                                <span aria-hidden="true"><img src="../../assets/images/chevron-right.svg"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div><!--./table-responsive-->
                    
                    <div class="card border-0 shadow" *ngIf="total_search_result == 0">
                        <div class="card-body row align-items-center justify-content-center" style="min-height:501px;">
        
                            <div class="col-auto">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-auto">
                                        <img width="138.39px" src="../../assets/images/dashboard-no-data.svg" />
                                    </div>
                                    <div class="col-12">
                                        <p class="text-center text-dashboard-muted mt-3">There is currently no data available.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--./tab-pane-->
                
            </div><!--./tab-content-->
            
        </div>
    </div>
    
    

    
</div>


<!-- Modal -->
<div  class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="modal_view" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
        
        <div class="modal-body">
            <h1 class="modal-title font-24 text-center" id="exampleModalLabel">{{this.form.controls["action"].value == 'add'? 'Add New User' : 'Update User'}}</h1>
            
            <form [formGroup]="form" class="py-3 px-5">
               

                <span class="text-success fw-bold font-20">Personal Info</span><br/>
                <small class="font-16">Update the users personal information</small>
                <br/>
                <br/>
                <div class="mb-3 row d-none">
                    <label class="col-sm-4 col-form-label">Action</label>
                    <div class="col-sm-8">
                        <input type="text" readonly class="form-control-plaintext" formControlName="action">
                    </div>
                </div>

                <div class="mb-3 row d-none">
                    <label class="col-sm-4 col-form-label">ID</label>
                    <div class="col-sm-8">
                        <input type="text" readonly class="form-control-plaintext" formControlName="id">
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div>
                            <label class="col-form-label font-12">First Name</label>
                            <input type="text" class="form-control {{form.get('first_name').touched && form.get('first_name').invalid? 'is-invalid' : ''}}" formControlName="first_name">
                            <small class="text-danger" *ngIf="form.get('first_name').touched && form.get('first_name').invalid && form.controls['first_name'].errors?.['required']">
                                {{field_label['first_name']}} is required
                            </small>

                            <small class="text-danger" *ngIf="form.get('first_name').touched && form.get('first_name').invalid && form.controls['first_name'].errors?.['pattern']">
                                {{field_label['first_name']}} is invalid format
                            </small>
                        </div>
                    </div>
                    <div class="col">
                        <div>
                            <label class="col-form-label font-12">Last Name</label>
                            <input type="text" class="form-control {{form.get('last_name').touched && form.get('last_name').invalid? 'is-invalid' : ''}}" formControlName="last_name">
                            <small class="text-danger" *ngIf="form.get('last_name').touched && form.get('last_name').invalid && form.controls['last_name'].errors?.['required']">
                                {{field_label['last_name']}} is required
                            </small>

                            <small class="text-danger" *ngIf="form.get('last_name').touched && form.get('last_name').invalid && form.controls['last_name'].errors?.['pattern']">
                                {{field_label['last_name']}} is invalid format
                            </small>
                        
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div>
                            <label class="col-form-label font-12">Email</label>
                            <input [readOnly]="readonly_group"  type="text" class="form-control {{form.get('username').touched && form.get('username').invalid? 'is-invalid' : ''}}" formControlName="username">
                            
                            <small class="text-danger" *ngIf="form.get('username').touched && form.get('username').invalid && form.controls['username'].errors?.['required']">
                                {{field_label['username']}} is required
                            </small>

                            <small class="text-danger" *ngIf="form.get('username').touched && form.get('username').invalid && form.controls['username'].errors?.['pattern']">
                                The {{field_label['username'].toLowerCase()}} format is invalid
                            </small>

                            <small class="text-danger" *ngIf="form.get('username').touched && form.get('username').invalid && form.controls['username'].errors?.['dup']">
                                The {{field_label['username'].toLowerCase()}} has already been added
                            </small>
                        
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col">
                        <div>
                            <label class="col-form-label font-12">Position</label>
                            <input [readOnly]="readonly_group"  type="text" class="form-control {{form.get('position').touched && form.get('position').invalid? 'is-invalid' : ''}}" formControlName="position">
                            
                            <small class="text-danger" *ngIf="form.get('position').touched && form.get('position').invalid && form.controls['position'].errors?.['required']">
                                {{field_label['position']}} is required
                            </small>


                        
                        </div>
                    </div>
                </div>

              

                <div class="row">
                    <div class="col">
                        <div>
                            <label class="col-form-label font-12">Role</label>
                            <select class="form-select {{form.get('user_type').touched && form.get('user_type').invalid? 'is-invalid' : ''}}" formControlName="user_type">
                                <option value="">-- Select --</option>
                                <option value="super_admin" *ngIf="global.login_session['user_type'] == 'super_admin'">Super Admin</option>
                                <option value="owner" *ngIf="global.login_session['user_type'] == 'super_admin'">Owner</option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                            </select>

                            <small class="text-danger" *ngIf="form.get('user_type').touched && form.get('user_type').invalid && form.controls['user_type'].errors?.['required']">
                                {{field_label['user_type']}} is required
                            </small>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="global.login_session['user_type'] == 'super_admin'">
                    <div class="col">
                        <div>
                            <label class="col-form-label font-12">Subscription</label>
                            <select (change)="select_sub()" class="form-select {{form.get('subscription').touched && form.get('subscription').invalid? 'is-invalid' : ''}}" formControlName="subscription">
                                <option value="">-- Select --</option>
                                <option *ngFor="let x of subscription_arr" [value]="x.id" >{{x.name}}</option>
                            </select>

                            <small class="text-danger" *ngIf="form.get('subscription').touched && form.get('subscription').invalid && form.controls['subscription'].errors?.['required']">
                                {{field_label['subscription']}} is required
                            </small>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        
                        <div class="font-16" *ngIf="selected_subscription_desc !== ''">
                            <br/>
                            {{selected_subscription_desc}}
                        </div>
                    </div>
                </div>

                <div class="row d-none">
                    <div class="col">
                        <div>
                            <label class="col-form-label font-12">Mobile Number</label>
                            <div class="d-grid gap-2">
                                <input formControlName="mobile_number" type="text" class="form-control {{form.get('mobile_number').touched && form.get('mobile_number').invalid? 'is-invalid' : ''}}" 
                                ng2TelInput
                                [ng2TelInputOptions]="{initialCountry: 'ph'}"
                                (countryChange)="onCountryChange($event)"
                                (intlTelInputObject)="telInputObject($event)" />
                            </div>
                        </div>
                    </div>
                </div>
               
                <div class="{{show_eye_icon? '' : 'd-none'}}">
                    <div class="row my-4">
                        <div class="col">
                            <hr/>
                        </div>
                    </div>

                    <span class="text-success fw-bold font-20">Password</span><br/>
                    <small class="font-16">Set the users password for their account</small>
                    <br/>
                    <br/>

                    <div class="mb-3 row d-none">
                        <label class="col-sm-4 col-form-label font-12">Username</label>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" formControlName="username_placeholder">
                        </div>
                    </div>


                    <div class="row d-none">
                        <div class="col">
                            <div>
                                <label class="col-form-label font-12">Password</label>
                                <input type="text" class="form-control" formControlName="password_placeholder">
                            </div>
                        </div>
                    </div>

                    

                    <div class="row">
                        <div class="col">
                            <label class="col-form-label font-12">Password</label>
                            <div class="input-group">
                                
                                <input [readOnly]="readonly_group" [style]="eye_icon_style" type="{{show_password ? 'text' : 'password'}}" class="form-control {{form.get('password').touched && form.get('password').invalid? 'is-invalid' : ''}}" formControlName="password">
                                <img [ngStyle]="{'border-color' : form.get('password').touched && form.get('password').invalid? '#dc3545' : ''}" *ngIf="show_eye_icon" class="input-group-text bg-transparent" (click)="s_password('password')" src="{{show_password? '../../assets/images/eye-slash-regular.svg' : '../../assets/images/eye-regular.svg'}}"/>
                                
                                
                            </div>
                            <small class="text-danger" *ngIf="form.get('password').touched && form.get('password').invalid && form.controls['password'].errors?.['required']">
                                {{field_label['password']}} is required
                            </small>

                            <small class="text-danger" *ngIf="form.get('password').touched && form.get('password').invalid && form.controls['password'].errors?.['minlength']">
                                {{field_label['password']}} must have 6 minimum characters
                            </small>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <label class="col-form-label font-12">Confirm Password</label>
                            <div class="input-group">
                                
                                <input [readOnly]="readonly_group" [style]="eye_icon_style" type="{{show_password2 ? 'text' : 'password'}}" class="form-control {{form.get('conf_password_placeholder').touched && form.get('conf_password_placeholder').invalid? 'is-invalid' : ''}}" formControlName="conf_password_placeholder">
                                <img [ngStyle]="{'border-color' : form.get('conf_password_placeholder').touched && form.get('conf_password_placeholder').invalid? '#dc3545' : ''}" *ngIf="show_eye_icon" class="input-group-text bg-transparent" (click)="s_password('conf_password_placeholder')" src="{{show_password2? '../../assets/images/eye-slash-regular.svg' : '../../assets/images/eye-regular.svg'}}"/>
                                
                                

                                

                            </div>

                            <small class="text-danger" *ngIf="form.get('conf_password_placeholder').touched && form.get('conf_password_placeholder').invalid && form.controls['conf_password_placeholder'].errors?.['required']">
                                {{field_label['conf_password_placeholder']}} is required
                            </small>

                            <small class="text-danger" *ngIf="form.get('conf_password_placeholder').touched && form.get('conf_password_placeholder').invalid && form.controls['conf_password_placeholder'].errors?.['password_match']">
                                Passwords do not match. Try again!
                            </small>

                            <small class="text-danger" *ngIf="form.get('conf_password_placeholder').touched && form.get('conf_password_placeholder').invalid && form.controls['conf_password_placeholder'].errors?.['minlength']">
                                {{field_label['conf_password_placeholder']}} must have 6 minimum characters
                            </small>
                        </div>
                    </div>
                </div>
                
            </form>

            <div class="row justify-content-center gx-1">
                <div class="col-xxl-3 col-xl-3 col-auto">
                    <div class="d-grid gap-2">
                      
                        <button type="button" data-bs-dismiss="modal" aria-label="Close" (click)="close()" class="btn btn-outline-primary font-700">Cancel</button>
                    </div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-auto">
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-primary font-700" (click)="save_data()">
                            {{this.form.controls["action"].value == 'add'? 'Create User' : 'Update User'}}
                        </button>
                    </div>
                </div>

            </div>

        </div>
        </div>
    </div>
</div>

<div class="modal fade" id="confirmation_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header border border-bottom-0">
                <button type="button" class="btn-close" #confirmation_close_button data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center align-items-center mb-3">
                    <div class="col-auto">
                        <img style="width:0.5in;height:0.5in;" class="img-fluid mx-auto d-block" src="../../assets/images/delete-icon.png" >
                    </div>
                </div>
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                        <p class="text-center">
                            <small class="col-form-label fw-bold" style="font-size: 9pt !important;color: rgba(49, 121, 108, 1);">Are you sure you want to remove {{for_delete["username"]}} from your team?</small>
                        </p>
                    </div>
                </div>
                

                <div class="row justify-content-center my-3">
                    <div class="col-xxl-5 col-xl-5 col-auto">
                        <div class="d-grid gap-2">
                        
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" (click)="close()" class="fw-bold btn btn-outline-primary btn-sm">Cancel</button>
                        </div>
                    </div>
                    
                    <div class="col-xxl-5 col-xl-5 col-auto">
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary btn-sm fw-bold" (click)="delete_data(for_delete)">Yes</button>
                        </div>
                    </div>
    
                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal fade" id="success_delete_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header border border-bottom-0">
                <button type="button" class="btn-close" #confirmation_close_button data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center align-items-center mb-3">
                    <div class="col-auto">
                        <img style="width:0.5in;height:0.5in;" class="img-fluid mx-auto d-block" src="../../assets/images/delete-icon-success.png" >
                    </div>
                </div>
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                        <p class="text-center">
                            <small class="col-form-label fw-bold" style="font-size: 9pt !important;color: rgba(49, 121, 108, 1);">You have successfully removed a user from your team</small>
                        </p>
                    </div>
                </div>
                

                <div class="row justify-content-center my-3">
                    
                    
                    <div class="col-auto">
                        <div class="d-grid gap-2">
                            <a href="main/user_management" class="btn btn-primary btn-sm fw-bold" (click)="delete_data(for_delete)">Back to manage users</a>
                        </div>
                    </div>
    
                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal fade" id="send_invite_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header border border-bottom-0">
                <button type="button" class="btn-close" #confirmation_close_button data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body px-4">
                <div class="row justify-content-center align-items-center mb-5">
                    <div class="col-auto">
                        <img style="width:160px;height:81px;" src="../../assets/images/email-sent.png" >
                    </div>
                </div>
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                        <p class="text-center">
                            <span style="color: rgba(49, 121, 108, 1) !important;font-size: 24px !important;font-weight: 700 !important;line-height: 28px !important;">Email Sent</span>
                        </p>

                        <p class="text-center">
                            <span style="color: rgba(75, 75, 75, 1) !important;font-size: 18px !important;font-weight: 400 !important;line-height: 20px !important;">
                                We've sent an email to <span style="color: rgba(59, 176, 115, 1) !important;">{{this.for_invite["username"]}}</span> with further instructions
                            </span>
                        </p>




                    </div>

                    
                    
                </div>
                

                
            </div>

        </div>
    </div>
</div>



<div  class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="team_view" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header border border-bottom-0">
                <button type="button" class="btn-close d-none" #confirmation_close_button data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h1 class="modal-title font-24 text-center" id="exampleModalLabel">{{this.form_team.controls["action"].value == 'update'? 'Update Team Name' : 'Add Team Name'}}</h1>    
                <form [formGroup]="form_team" class="py-3 px-5 mb-3">
                    <div class="mb-3 row d-none">
                        <label class="col-sm-4 col-form-label">Action</label>
                        <div class="col-sm-8">
                            <input type="text" readonly class="form-control-plaintext" formControlName="action">
                        </div>
                    </div>
    
                    <div class="mb-3 row d-none">
                        <label class="col-sm-4 col-form-label">ID</label>
                        <div class="col-sm-8">
                            <input type="text" readonly class="form-control-plaintext" formControlName="id">
                        </div>
                    </div>

                    <div class="row d-none">
                        <div class="col">
                            <div>
                                <label class="col-form-label font-12">Team ID</label>
                                <input readonly type="text" class="form-control {{form_team.get('id').touched && form.get('id').invalid? 'is-invalid' : ''}}" formControlName="id">
                                
                            </div>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col">
                            <div>
                                <label class="col-form-label font-12">Team Name</label>
                                <input type="text" class="form-control {{form_team.get('name').touched && form_team.get('name').invalid? 'is-invalid' : ''}}" formControlName="name">
                                <small class="text-danger" *ngIf="form_team.get('name').touched && form_team.get('name').invalid && form_team.controls['name'].errors?.['required']">
                                    {{field_label['name']}} is required
                                </small>
    
                                <small class="text-danger" *ngIf="form_team.get('name').touched && form_team.get('name').invalid && form_team.controls['name'].errors?.['pattern']">
                                    {{field_label['name']}} is invalid format
                                </small>
                            </div>
                        </div>
                    </div>
                </form><!--./form-->

                <div class="row justify-content-center gx-1">
                    <div class="col-xxl-3 col-xl-3 col-auto">
                        <div class="d-grid gap-2">
                          
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-outline-primary font-700">Cancel</button>
                        </div>
                    </div>
                    <div class="col-xxl-4 col-xl-4 col-auto">
                        <div class="d-grid gap-2">
                            <button type="button" class="btn btn-primary font-700" (click)="save_team()">
                                Save
                            </button>
                        </div>
                    </div>
    
                </div><!--./row-->

            </div><!--./modal-content-->
        </div><!--./modal-content-->
    </div><!--./modal-dialog-->
</div>