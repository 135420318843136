import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Globals } from '../global/global-variable';
import { DashboardService } from '../services/dashboard-service';
import { ToastrService } from 'ngx-toastr'
import { lastValueFrom } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { CommonService } from '../library/pcl_lib';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
    //loading
    
    
    table_list                  = new Array();
    data_list                   = new Array();
    show_no_data                = false;
    show_total_search_result    = 0;
    
    filter_form = this.fb.group({
        filter_search : [''],
        page_size_placeholder : [10],
    });


    //for pagination
    previous                    = false;
    lastVisibleField            = "id";
    lastVisibleVal              = "";
    
    prevPageVisibleVal          = new Array();

   
    pageNumber                  = 0;
    row_counter                 = 0;
    pagination_counter          = 0;
    pageSize                    = [10,20,50,100];

    //show_total_search_result    = false;
    total_search_result         = 0;
    total_result                = 0;
    show_spinner = false;
    subscription = {};
    
    constructor(
        private dashboardService : DashboardService,
        public global : Globals,
        private toastr : ToastrService,
        private datePipe : DatePipe,
        private fb : FormBuilder,
        public lib : CommonService
        
    ){
      
    }

    ngOnInit(){
        //if(localStorage.getItem('accessToken') !== null){
            this.refresh_list();
        //}//end if
    }//end

    

    

    refresh_list(){
        this.global.loading = true;
        const val               = this.filter_form.value;
        
        const query_param = {
            "created_by" : this.global.login_session["id"],
            "records" : [
                {
                    "record_type" : environment.individual,
                    "result_type" : "total"
                },
                {
                    "record_type" : environment.setting,
                    "result_type" : "total"
                },
                {
                    "record_type" : environment.dashboard_counter,
                    "result_type" : "total",
                    "filterFieldArr" : [
                        "type",
                        "created_by"
                    ],
                    "filterOperatorArr" : [
                        "==",
                        "=="
                    ],
                    "filterValueArr" : [
                        "search_count",
                        this.global.login_session["id"]
                    ]
                },
                {
                    "record_type" : environment.dashboard_counter,
                    "result_type" : "total_field",
                    "result_field" : "query_result",
                    "filterFieldArr" : [
                        "type",
                        "created_by"
                    ],
                    "filterOperatorArr" : [
                        "==",
                        "=="
                    ],
                    "filterValueArr" : [
                        "hit_count",
                        this.global.login_session["id"]
                    ]
                }
            ]
        }//end

        //return this.settingService.filterList(query_param);
        this.dashboardService.get_dashboard_counter(query_param).subscribe(async res => {
            if(res["success"]){
                
                this.table_list = res["data"];
                console.log('table_list',res);
                if(res["subscription"] !== undefined){
                    this.subscription = res["subscription"];

                    if(this.subscription["next_due_date"] !== undefined){
                        var u_date = new Date(this.subscription["next_due_date"]["_seconds"] * 1000 + this.subscription["next_due_date"]["_nanoseconds"]/1000000);
                        this.subscription["next_due_date_parsed"] = this.datePipe.transform(u_date, 'MMM dd, yyyy');
                        //this.subscription["subscription_name"] = (this.subscription["subscription_name"]);
                    }//end if
                    
                }//end if
                
                this.global.loading = false;
                this.refresh_recent_search();
                

            }else{
                console.log(res);
                this.toastr.error(res["data"]["message"],"System Message");
            }//end if
            
          
            this.global.loading = false;
        });


        

        
    }//end

    async refresh_recent_search(){
        const val = this.filter_form.value;
        this.show_spinner = true;
        const recent_search = await lastValueFrom(this.refresh_list_recent_search());
        console.log(recent_search);
        
        if(recent_search["success"]){
            this.data_list                  = recent_search["data"]["query_data"];

            //convert date
            for(var k in this.data_list){
                const es = this.data_list[k];
                if(es["date_created"] !== undefined){
                    var u_date = new Date(es["date_created"]["_seconds"] * 1000 + es["date_created"]["_nanoseconds"]/1000000);
                    this.data_list[k]["date_created_str"] = this.datePipe.transform(u_date, 'MMM dd, yyyy h:mm:ss a');
                }//end if
            }//end forthis.datePipe.transform((new Date), 'MMM dd, yyyy h:mm:ss a');
            //convert date

            this.show_total_search_result   = recent_search["data"]["query_result"];
            this.total_search_result        = recent_search["data"]["total_result"];
            this.total_result               = recent_search["data"]["total_result"];

            this.row_counter                = (this.pageNumber * val.page_size_placeholder) + 1;
            this.pagination_counter         = this.row_counter + (this.data_list.length - 1);
            this.show_spinner = false;
            
        }//end if
    }

    refresh_list_recent_search(){
        const val               = this.filter_form.value;
        const query_param = {
            "record_type" : environment.dashboard_counter,
            "pageNumber" : this.pageNumber,
            "lastVisibleField" : this.lastVisibleField,
            "lastVisibleVal" : this.lastVisibleVal,
            "previous" : this.previous,
            "pageSize" : val.page_size_placeholder,
            "filterFieldArr" : [
                "created_by",
                "type"
            ],
            "filterOperatorArr" : [
                "==",
                "=="
            ],
            "filterValueArr" : [
                this.global.login_session["id"],
                "search_count"
            ],
            
            "orderByFieldArr" : [
                "date_created",
                "id"
            ],
            "sortOrderArr" : [
                "desc",
                "asc"
            ]
        }//end

        return this.dashboardService.filterList(query_param);


        
    }//end

    search(){
        this.show_total_search_result    = 0;
        this.show_no_data = false;
       // const val = this.form.value;
        this.previous                    = false;
        this.lastVisibleField            = "id";
        this.lastVisibleVal              = "";
        
        this. prevPageVisibleVal          = new Array();
       
        this.pageNumber                  = 0;
        //this.refresh_list()
        this.refresh_recent_search();
    }

    prev(){
        this.show_total_search_result    = 0;
        this.show_no_data = false;
        const val           = this.filter_form.value;;
        if(this.pageNumber > 0){
            this.pageNumber -= 1;
            this.previous = true;
            this.lastVisibleVal         = this.prevPageVisibleVal[this.pageNumber];
            this.refresh_recent_search();
        }//end if
        
        
        
    }

    next(){
        this.show_total_search_result    = 0;
        this.show_no_data = false;
       
        const val           = this.filter_form.value;;
        const total_pages   = Math.ceil((this.total_result / val.page_size_placeholder));
        console.log('total_pages',total_pages);
        
        if(total_pages > (this.pageNumber + 1)){
            
            this.pageNumber             += 1;
            this.previous               = false;
            this.lastVisibleVal         = this.data_list[this.data_list.length - 1]["id"];
            
            if(!this.prevPageVisibleVal.includes(this.data_list[0]["id"])){
                this.prevPageVisibleVal.push(this.data_list[0]["id"]);
            }//end if
            
            

            this.refresh_recent_search();
            
            
        }//end if
    }
}
