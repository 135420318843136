import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../global/global-variable';
import { UserManagementService } from '../services/user-management-service';
@Component({
  selector: 'app-user-invite-validate',
  templateUrl: './user-invite-validate.component.html',
  styleUrls: ['./user-invite-validate.component.scss']
})
export class UserInviteValidateComponent {
    snapshot_param = {}; //snapshot parameter
    message = "";
    message_class = "";
    constructor(
        private _activatedRouter : ActivatedRoute,
        public globals : Globals,
        private userManagementService : UserManagementService
    ) { 
        this.snapshot_param = this._activatedRouter.snapshot.params;
    }

    ngOnInit(){
        this.globals.loading = true;

        var request_param = {
            "id" : this.snapshot_param["id"],
            "team_id" : this.snapshot_param["team_id"],
            "idToken" : this.snapshot_param["idToken"]
        }
        this.userManagementService.validate_user_invite(request_param).subscribe((result) => {
            console.log(result);
            if(result["success"]){
                this.message_class = "text-success";
                this.message = "You have successfully joined the team! Redirecting to login page...";
                setTimeout(() => {
                    window.location.replace('login');
                }, 3000);
            }else{
                this.message_class = "text-danger";
                this.message = result["data"]["error"]["message"];
            }
            this.globals.loading = false;
        });
    }
}
