import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../global/global-variable';
import { CheckOutService } from '../services/checkout-service';
@Component({
  selector: 'app-maya-verification',
  templateUrl: './maya-verification.component.html',
  styleUrls: ['./maya-verification.component.scss']
})
export class MayaVerificationComponent {
    public snapshot_param = {};
    query_params = {};
    response_success = null;
    response_error = {};
    constructor(
        private _activatedRouter : ActivatedRoute,
        public globals : Globals,
        private checkoutService : CheckOutService
    ){
        this.snapshot_param = _activatedRouter.snapshot.params;
        _activatedRouter.queryParams.subscribe((params) => {
            this.query_params = params;
        });
    }

    ngOnInit(){
        this.globals.loading = true;
        console.log("query_param",this.query_params);
        
        var request_param = {
            "session_id" : this.query_params["session_id"],
            "status" : this.snapshot_param["status"]
        }
        console.log('request_param',request_param);
        this.checkoutService.process_subscription_status_zipph(request_param).subscribe((res) => {
            console.log("res",res);
            this.response_success = res["success"];
            if(res["success"]){
                
            }else{
                this.response_error = res["data"]["error"];
                console.log(this.response_error);
            }//end if
            this.globals.loading = false;
            
            
        })
       

        
        
    }
}
